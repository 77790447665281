import React, {useState, useContext} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col} from 'reactstrap';
import {Redirect, useHistory} from "react-router-dom";

import logo from '../assets/logo.png';
import logoOn from '../assets/logo-on.png';
import AppContext from '../AppContext.js';
import PageURL from "../PageURL.js";

const PageLogin = function() {
	const [username, setUsername] = useState("");
	const [usernameError, setUsernameError] = useState("");
	const [password, setPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const { appState, setAppState } = useContext(AppContext);
	const history = useHistory();

	const handleSubmit = (evt) => {
    evt.preventDefault();
    let error = false;
    if (username.length < 1) {
    	setUsernameError("Username cannot be blank.");
    	error = true;
    } else setUsernameError("");
    if (password.length < 1) {
    	setPasswordError("Password cannot be blank.");
    	error = true;
    } else setPasswordError("");
    
    if (error == false) {
    	setAppState({
    		user:username,
    		password:password
    	});
    	history.push(PageURL.Home);
    }
  };

	return (
	<div className="login-page">
		<Row>
			<Col xl="3" className="login-content">
      	<div className="content d-flex align-items-center">
      	  <Row>
      	    <Col xs="12">
      	      <div className="logo-content mb-3"><img alt="logo" src={logo} /></div>
      	    </Col>
      	    <Col xs="12">
      	      <div className="text-content">
      	        Van Leeuwen is a globally operating trading company specialized in steel pipes, and pipe and tube applications. We are active in virtually all industrial sectors. With some forty branches spread throughout Europe, the Middle East, Asia, Australia and America, we ensure that materials are available anywhere in the world, customized wherever needed. Our employees have specialist knowledge of sourcing, project management, logistics and stock planning and work closely together with our customers in the Industry and Energy markets. The combination of global logistics and knowledge of products and customer applications makes Van Leeuwen a leading company in its markets.
      	      </div>
      	    </Col>
      	  </Row>
      	</div>
     	</Col>
     	<Col xl="9" className="login-form">
     		<div className="form d-flex justify-content-center align-items-center">
     			<Row>
     				<Col xs="12">
              <div className="logo-form mb-3"><img alt="logo" src={logoOn} /></div>
            </Col>
            <Col xs="12">
            	<Form onSubmit={handleSubmit}>
								<FormGroup>
									<Label className="form-control-label" for="username">Username</Label>
									<Input className={'form-control ' + (usernameError !== "" ? "is-invalid" : "")} id="username" autoComplete="username" placeholder="Enter Your Username" value={username} onChange={e => setUsername(e.target.value)} />
									{usernameError !== "" && <div className="invalid-feedback">{usernameError}</div>}
								</FormGroup>
								<FormGroup>
									<Label className="form-control-label" for="password">Password</Label>
									<Input className={'form-control ' + (passwordError !== "" ? "is-invalid" : "")} id="password" autoComplete="current-password" type="password" placeholder="Enter Your Password" onChange={e => setPassword(e.target.value)} />
									{passwordError !== "" && <div className="invalid-feedback">{passwordError}</div>}
								</FormGroup>
								<Button type="submit" color="primary">Submit</Button>
							</Form>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
	</div>
	);
};

export default PageLogin;