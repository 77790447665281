import React, {useState, useContext, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  NavbarText,
  Container,
  Row
} from 'reactstrap';

import PageURL from "../PageURL.js";
import AppContext from '../AppContext.js';
import logo from '../assets/logo.png';
import PageDOP from './PageDOP.js';
import PageMyAccount from './PageMyAccount.js';
import PageCertificates from "./PageCertificates.js";
import PageCustomerAccounts from "./PageCustomerAccounts.js";

const Dashboard = function() {
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const toggleMenu = function() {
    setMenuIsOpen(!menuIsOpen);
  };
  const toggleDropdown = function() {
    setDropdownIsOpen(!dropdownIsOpen);
  };

  return (
  <Fragment>
    <header className="header">
      <div className="container-fluid">
        <Row>
          <Navbar expand="md" className="w-100 py-0 header-navigation">
            <div className="navbar-brand"><Link to={PageURL.Home} className="logo-box"><img className="logo my-2" alt="logo" src={logo} /></Link></div>
            <NavbarToggler onClick={toggleMenu} />
            <Collapse isOpen={menuIsOpen} navbar className="justify-content-end">
              <Nav className="ml-auto nav-font" navbar>
                <NavItem>
                  <NavLink tag={Link} to={PageURL.Home}>Certificates</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink tag={Link} to={PageURL.DOP}>DOP</NavLink>
                </NavItem>
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret>
                    Accounts
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem>
                      <NavLink style={{color: "black"}} tag={Link} to={PageURL.customerAccounts}>Customer Accounts</NavLink>
                    </DropdownItem>
                    <DropdownItem>
                      <NavLink style={{color: "black"}} tag={Link} to={PageURL.myAccount}>User Accounts</NavLink>
                    </DropdownItem>
                    <DropdownItem divider />
                    <DropdownItem>
                      <NavLink style={{color: "black"}} tag={Link} to={PageURL.myAccount}>My Account</NavLink>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <NavItem>
                  <NavLink tag={Link} to={PageURL.Home}>Log Out</NavLink>
                </NavItem>
              </Nav>
            </Collapse>
          </Navbar>
        </Row>
      </div>
    </header>
    <Container fluid className="page-content">
      <Route exact path={PageURL.customerAccounts}>
        <PageCustomerAccounts />
      </Route>
      <Route exact path={PageURL.myAccount}>
        <PageMyAccount />
      </Route>
      <Route exact path={PageURL.DOP}>
        <PageDOP />
      </Route>
      <Route exact path={PageURL.Home}>
        <PageCertificates />
      </Route>
    </Container>
  </Fragment>
  );
};

export default Dashboard;