import React, {useState, Fragment, useEffect} from 'react';
import {Form, Row, Col, Button, Label, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, Nav, NavItem, NavLink, Tab, TabContent, TabPane, Card, CardTitle, CardText, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit} from '@fortawesome/free-solid-svg-icons'

import CustomTable from '../Utils/CustomTable.js';

const PageCustomerAccounts = function() {
	const [customerCode, setCustomerCode] = useState("");
	const [companyName, setCompanyName] = useState("");
	const [repeatNewPassword, setRepeatNewPassword] = useState("");
	const [modalObject, setModalObject] = useState({});
	const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
	const tableColumns = [
      //Text, Class
    ["Business Partner ID", ""],
    ["Company Code", ""],
    ["Company Name", ""],
    ["Country", ""],
    ["Account Manager", ""],
    ["Is Mother", ""],
    ["Actions", ""]
  ];
  const columnKeys = [
      //Object key, Class
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["__actions", ""]
  ];
  const contactPersonColumns = [
      //Text, Class
    ["Name", ""],
    ["Last Name", ""],
    ["User Name", ""]
  ];
  const contactPersonColumnKeys = [
      //Object key, Class
    ["name", ""],
    ["name", ""],
    ["name", ""]
  ];
  const tableActionEditIcon = <FontAwesomeIcon icon={faEdit} color="green" title="Edit"></FontAwesomeIcon>;
  const tableActionEdit = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
      //@TODO(muamer) This is only for testing since we don't have these fields in the api !!
    modalObject.isMotherCompany = false;
    modalObject.portalPagesDOP = false;
    modalObject.portalPagesCertificates = false;
    modalObject.certificateModel = "2";
      //DELETE TO PREVIOUS TODO
    setModalObject(modalObject);
  	setModalEditIsOpen(true);
  };
  const modalCustomerSubmit = function() {

  };
  const modalCertificateSubmit = function() {

  };
  const[check, setCheck] = useState(false);

  const toggleModalEditIsOpen = () => setModalEditIsOpen(!modalEditIsOpen);
  const toggleActiveTab = tab => {if(activeTab !== tab) setActiveTab(tab);}
  const toggleCheckBox = function(key) {
    setModalObject(function(previousModalObject) {
      let modalObject = Object.assign({}, previousModalObject);
      modalObject[key] = !modalObject[key];
      return modalObject;
    });
  };

  const dataReceivedCallback = function(data) {
  	for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__actions = [
        <span onClick={() => tableActionEdit(data[i])}>{tableActionEditIcon}</span>
      ]
    }
  	return data;
  };
  const contactPersonCallback = function(data) {
    return data;
  };

	const searchSubmit = (evt) => {
    evt.preventDefault();
  };

  useEffect(() => {
    document.title = "VLBC - Customer Accounts";
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <h1 className="page-title">Customer Accounts</h1>
    </div>
    <Container fluid className="element-box">
  	  <Row>
  	  	<Col md="5">
  	  		<Label>Customer Code</Label>
  	  		<Input placeholder="Customer Code" value={customerCode} onChange={e => setCustomerCode(e.target.value)} />
  	  	</Col>
  	  	<Col md="5">
  	  		<Label>Company Name</Label>
  	  		<Input placeholder="Company Name" value={companyName} onChange={e => setCompanyName(e.target.value)} />
  	  	</Col>
  	  	<Col md="2" className="text-center align-self-end">
  	  		<Button color="primary" onClick={(evt) => searchSubmit(evt)}>Search</Button>
  	  	</Col>
  	  </Row>
    </Container>
  	<Container fluid className="element-box">
      <CustomTable columns={tableColumns} columnKeys={columnKeys} apiURL="https://jsonplaceholder.typicode.com/comments" dataReceivedCallback={dataReceivedCallback}></CustomTable>
    </Container>
  	<Modal isOpen={modalEditIsOpen} className="modal-dialog modal-xl" toggle={toggleModalEditIsOpen}>
    <ModalHeader>{modalObject.name}</ModalHeader>
    <ModalBody>
      <Row>
      <Nav tabs>
        <NavItem>
          <NavLink
            className={{ active: activeTab === '1' }}
            style={{cursor: "pointer"}}
            onClick={() => {toggleActiveTab('1')}}
          >
            Customer
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === '2' }}
            style={{cursor: "pointer"}}
            onClick={() => {toggleActiveTab('2')}}
          >
            Contact Persons
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={{ active: activeTab === '3' }}
            style={{cursor: "pointer"}}
            onClick={() => {toggleActiveTab('3')}}
          >
            Certificates
          </NavLink>
        </NavItem>
      </Nav>
      </Row>
      <TabContent activeTab={activeTab}>
        <div style={{height: "1rem"}}></div>
        <TabPane tabId="1">
          <div className="title-box">
            <h1 className="page-title">Details</h1>
          </div>
          <Container fluid className="element-box">
            <div>
              Company Code: {modalObject.name}
            </div>
            <div>
              Company: {modalObject.name}
            </div>
            <div>
              Country: {modalObject.name}
            </div>
            <div>
              <Row>
                <Col sm="6" className="content d-flex align-items-center">
                  Account Manager:
                </Col>
                <Col sm="6">
                  <Input type="select" defaultValue={modalObject.name} onChange={(e) => setModalObject(function(prevMO){prevMO.name=e.target.value; return prevMO;})}><option value="1">1</option><option value="2">2</option><option value="3">3</option></Input>
                </Col>
              </Row>
            </div>
            <FormGroup check>
              <Label check onClick={(e) => toggleCheckBox("isMotherCompany")}>
                <Input type="checkbox" />{' '}
                Mother Company
              </Label>
            </FormGroup>
          </Container>
          <div className="title-box" style={{margin: "1"}}>
            <h1 className="page-title">Portal Pages</h1>
          </div>
          <Container fluid className="element-box">
            <FormGroup check>
              <Label check onClick={(e) => toggleCheckBox("portalPagesDOP")}>
                <Input type="checkbox" />{' '}
                DOP
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check onClick={(e) => toggleCheckBox("portalPagesCertificates")}>
                <Input type="checkbox" />{' '}
                Certificates
              </Label>
            </FormGroup>
          </Container>
        </TabPane>
        <TabPane tabId="2">
          <CustomTable columns={contactPersonColumns} columnKeys={contactPersonColumnKeys} apiURL="https://jsonplaceholder.typicode.com/comments" dataReceivedCallback={contactPersonCallback}></CustomTable>
        </TabPane>
        <TabPane tabId="3">
          <div className="title-box">
            <h1 className="page-title">Certificate Details</h1>
          </div>
          <Container fluid className="element-box">
            <FormGroup tag="fieldset">
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="certificate-model" value="1" checked={modalObject.certificateModel === "1"} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {certificateModel: "1"}))}/>{' '}
                  Model 1 - One year access
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="certificate-model" value="2" checked={modalObject.certificateModel === "2"} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {certificateModel: "2"}))}/>{' '}
                  Model 2 - Certificate Credit x times 50
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="certificate-model" value="3" checked={modalObject.certificateModel === "3"} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {certificateModel: "3"}))}/>{' '}
                  Model 3 - Unlimited Access
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="certificate-model" value="4" checked={modalObject.certificateModel === "4"} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {certificateModel: "4"}))}/>{' '}
                  Model 4 - Pay per quarter for actual usage
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input type="radio" name="certificate-model" value="5" checked={modalObject.certificateModel === "5"} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {certificateModel: "5"}))}/>{' '}
                  Model 5 - Certificates as ordered
                </Label>
              </FormGroup>
            </FormGroup>
          </Container>
        </TabPane>
      </TabContent>
    </ModalBody>
    <ModalFooter>
      {activeTab === "1" &&
      <Fragment>
        <Button color="primary" onClick={() => modalCustomerSubmit()}>Update Customer</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </Fragment>}
      {activeTab === "2" &&
      <Fragment>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </Fragment>}
      {activeTab === "3" &&
      <Fragment>
        <Button color="primary" onClick={() => modalCertificateSubmit()}>Update Certificate</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </Fragment>}
    </ModalFooter>
    </Modal>
  </Fragment>
  );
};

export default PageCustomerAccounts;