import React, {useState, useEffect, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {Form, Row, Col, Button, Collapse, FormGroup, Input, Table, Modal, ModalHeader, ModalBody, ModalFooter, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTrashAlt, faEdit, faFilePdf} from '@fortawesome/free-solid-svg-icons';

import CustomTable from '../Utils/CustomTable.js';

const PageCertificates = function() {
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [modalAddIsOpen, setModalAddIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [modalEditIsOpen, setModalEditIsOpen] = useState(false);
  const [modalObject, setModalObject] = useState({});
  const [salesOrder, setSalesOrder] = useState("");
  const [delivery, setDelivery] = useState("");
  const [customerReference, setCustomerReference] = useState("");
  const [lineReference, setLineReference] = useState("");
  const [item, setItem] = useState("");
  const [heatNumber, setHeatNumber] = useState("");
  const [customerCode, setCustomerCode] = useState("");
  const [company, setCompany] = useState("");
  const tableColumns = [
      //Text, Class
    ["Company", ""],
    ["Customer Code", ""],
    ["Sales Order", ""],
    ["SO Line", ""],
    ["Delivery", ""],
    ["Del Line", ""],
    ["Del Doc Date", ""],
    ["Customer Reference", ""],
    ["Line Reference", ""],
    ["Item Code", ""],
    ["Item Description", ""],
    ["Heat Number", ""],
    ["Actions", "table-certificates-actions-header"]
  ];
  const columnKeys = [
      //Object key, Class
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["name", ""],
    ["__actions", "table-certificates-actions-data"]
  ];
  const tableActionDeleteIcon = <FontAwesomeIcon icon={faTrashAlt} color="red" title="Delete"></FontAwesomeIcon>;
  const tableActionEditIcon = <FontAwesomeIcon icon={faEdit} color="green" title="Edit"></FontAwesomeIcon>;
  const tableActionPdfIcon = <FontAwesomeIcon icon={faFilePdf} color="blue" title="Download PDF"></FontAwesomeIcon>;
  const tableActionDelete = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    setModalObject(modalObject);
    setModalDeleteIsOpen(true);
  };
  const tableActionEdit = function(dataItem) {
    let modalObject = Object.assign({}, dataItem);
    setModalObject(modalObject);
    setModalEditIsOpen(true);
  };
  const tableActionPdf = function(dataItem) {
    
  };
  const openModalAddAction = function() {
    setModalObject({});
    toggleModalAddIsOpen();
  };
  const buttonOpenModalAdd = <Button color="primary" onClick={() => openModalAddAction()}>+</Button>
  

  const toggleSearchIsOpen = () => setSearchIsOpen(!searchIsOpen);
  const toggleModalDeleteIsOpen = () => setModalDeleteIsOpen(!modalDeleteIsOpen);
  const toggleModalAddIsOpen = () => setModalAddIsOpen(!modalAddIsOpen);
  const toggleModalEditIsOpen = () => setModalEditIsOpen(!modalEditIsOpen);
  const modalDeleteSubmit = function() {
    console.log("DELETED:"+modalObject.name);
  };
  const modalEditSubmit = function() {
    console.log("UPDATED:"+modalObject.name);
  };
  const modalAddSubmit = function() {
    console.log("ADDED::"+modalObject.name);
  };

  const dataReceivedCallback = function(data) {
    for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__actions = [
        <span onClick={() => tableActionDelete(data[i])}>{tableActionDeleteIcon}</span>,
        <span onClick={() => tableActionEdit(data[i])}>{tableActionEditIcon}</span>,
        <span onClick={() => tableActionPdf(data[i])}>{tableActionPdfIcon}</span>
      ]
    }
    return data;
  }
  
  useEffect(() => {
    document.title = "VLBC - Certificates";
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <h1 className="page-title">Certificates</h1>
    </div>
    <Container fluid className="element-box">
      <Row>
        <Col md="12">
          <Button onClick={toggleSearchIsOpen} style={{width: "100%"}}>Search</Button>
        </Col>
      </Row>
      <Collapse isOpen={searchIsOpen}>
        <Form>
          <Row>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Sales Order" value={salesOrder} onChange={e => setSalesOrder(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Delivery" value={delivery} onChange={e => setDelivery(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Customer Reference" value={customerReference} onChange={e => setCustomerReference(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Line Reference" value={lineReference} onChange={e => setLineReference(e.target.value)}/>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Item" value={item} onChange={e => setItem(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Heat Number" value={heatNumber} onChange={e => setHeatNumber(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Customer Code" value={customerCode} onChange={e => setCustomerCode(e.target.value)}/>
              </FormGroup>
            </Col>
            <Col md="3">
              <FormGroup>
                <Input type="text" placeholder="Company" value={company} onChange={e => setCompany(e.target.value)}/>
              </FormGroup>
            </Col>
          </Row>
        </Form>
      </Collapse> 
    </Container>
    <div className="element-box">
      <CustomTable columns={tableColumns} columnKeys={columnKeys} apiURL="https://jsonplaceholder.typicode.com/comments" dataReceivedCallback={dataReceivedCallback} addToPaginationInBar={[buttonOpenModalAdd]} />
    </div>
    <Modal isOpen={modalEditIsOpen} className="modal-dialog modal-xl" toggle={toggleModalEditIsOpen}>
      <ModalHeader>{modalObject.name}</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="5">
            <Row>
              <Col md="6"><span>Customer Code:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
            <Row>
              <Col md="6"><span>Sales Order:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
            <Row>
              <Col md="6"><span>SO Line:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
            <Row>
              <Col md="6"><span>Delivery:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
            <Row>
              <Col md="6"><span>Del Line:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
            <Row>
              <Col md="6"><span>Del Doc Date:</span></Col>
              <Col md="6"><span>{modalObject.name}</span></Col>
            </Row>
          </Col>
          <Col md="7">
            <Row>
              <Col md="4" className="align-self-center"><span>Customer Reference:</span></Col>
              <Col md="8"><Input type="text" placeholder="Customer Reference" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Line Reference:</span></Col>
              <Col md="8"><Input type="text" placeholder="Line Reference" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Item Code:</span></Col>
              <Col md="8"><Input type="text" placeholder="Item Code" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Item Description:</span></Col>
              <Col md="8"><Input type="text" placeholder="Item Description" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Heat Number:</span></Col>
              <Col md="8"><Input type="text" placeholder="Heat Number" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => modalEditSubmit()}>Update</Button>
        <Button color="secondary" onClick={() => toggleModalEditIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>
    <Modal isOpen={modalDeleteIsOpen} className="modal-lg" toggle={toggleModalDeleteIsOpen}>
      <ModalHeader>Delete Certificate : {modalObject.name}</ModalHeader>
      <ModalBody>
        <h3>Do you really want to delete this certificate ?</h3>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => modalDeleteSubmit()}>Delete</Button>
        <Button color="secondary" onClick={() => toggleModalDeleteIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>
    <Modal isOpen={modalAddIsOpen} className="modal-dialog modal-xl" toggle={toggleModalAddIsOpen}>
      <ModalHeader>Add New Certificate</ModalHeader>
      <ModalBody>
        <Row>
          <Col md="6">
            <Row>
              <Col md="4" className="align-self-center"><span>Customer Code:</span></Col>
              <Col md="8"><Input type="text" placeholder="Customer Code" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Sales Order:</span></Col>
              <Col md="8"><Input type="text" placeholder="Sales Order" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>SO Line:</span></Col>
              <Col md="8"><Input type="text" placeholder="SO Line" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Delivery:</span></Col>
              <Col md="8"><Input type="text" placeholder="Delivery" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Del Line:</span></Col>
              <Col md="8"><Input type="text" placeholder="Del Line" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Del Doc Date:</span></Col>
              <Col md="8"><Input type="text" placeholder="Del Doc Date" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
          </Col>
          <Col md="6">
            <Row>
              <Col md="4" className="align-self-center"><span>Customer Reference:</span></Col>
              <Col md="8"><Input type="text" placeholder="Customer Reference" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Line Reference:</span></Col>
              <Col md="8"><Input type="text" placeholder="Line Reference" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Item Code:</span></Col>
              <Col md="8"><Input type="text" placeholder="Item Code" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Item Description:</span></Col>
              <Col md="8"><Input type="text" placeholder="Item Description" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
            <Row>
              <Col md="4" className="align-self-center"><span>Heat Number:</span></Col>
              <Col md="8"><Input type="text" placeholder="Heat Number" value={modalObject.name} onChange={e => setModalObject((prevMO) => Object.assign({}, prevMO, {name: e.target.value}))}/></Col>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => modalAddSubmit()}>Add</Button>
        <Button color="secondary" onClick={() => toggleModalAddIsOpen()}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </Fragment>
  );
};

export default PageCertificates;