import React from 'react';
import { BrowserRouter as Router, Route, Switch, Link, Redirect } from "react-router-dom";

import AppContext from './AppContext.js';
import PageLogin from './Components/PageLogin.js';
import Dashboard from './Components/Dashboard.js';
import { Error404 } from './Components/Errors.js';

const App = function(props) {
  const [appState, setAppState] = React.useState({
    user : '',
    password : ''
  });

  if (appState.user === undefined || appState.user === '') {
    return (
      <Router>
        <AppContext.Provider value={{ appState, setAppState }}>
          <div className="full-page">
            <Route exact path="/login">
              <PageLogin />
            </Route>
            <Route>
              <Redirect to="/login" />
            </Route>
          </div>
        </AppContext.Provider>
      </Router>
    );
  }
  
  return (
    <Router>
      <AppContext.Provider value={{ appState, setAppState }}>
        <div className="full-page">
          <Route>
            <Dashboard />
          </Route>
          <Route exact path="/login">
            <Redirect to="/" />
          </Route>
        </div>
      </AppContext.Provider>
    </Router>
  );
};

export default App;