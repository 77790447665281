import React, {useState, useEffect, Fragment} from 'react';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";
import {Row, Col, Label, Input, Button, Container} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

import CustomTable from '../Utils/CustomTable.js';

const PageDOP = function() {
  const [supplier, setSupplier] = useState("");
  const tableColumns = [
      //Text, Class
    ["Supplier Name", ""],
    ["Document", ""],
    ["Download", ""]
  ];
  const columnKeys = [
      //Object key, Class
    ["name", ""],
    ["name", ""],
    ["__actions", "table-dop-actions-data"]
  ];
  const tableActionPdfIcon = <FontAwesomeIcon icon={faFilePdf} color="red" title="Download PDF"></FontAwesomeIcon>;
  const tableActionPdf = function(dataItem) {

  };

  const dataReceivedCallback = function(data) {
    for (let i = 0, n = data.length; i < n; i++) {
      data[i].__id = i;
      data[i].__actions = [
        <span onClick={() => tableActionPdf(data[i])}>{tableActionPdfIcon}</span>
      ]
    }
    return data;
  };

  const searchSubmit = function(evt) {
  };

  useEffect(() => {
    document.title = "VLBC - DOP";
  }, []);

  return (
  <Fragment>
    <div className="title-box">
      <h1 className="page-title">Declaration of Performance</h1>
    </div>
    <Container fluid className="element-box">
      <Row>
        <Col md="6">
          <Label for="supplier">Supplier</Label>
          <Input id="supplier" autoComplete="supplier" placeholder="Supplier" value={supplier} onChange={e => setSupplier(e.target.value)} />
        </Col>
        <Col md="6" className="content d-flex align-items-end">
          <Button className="mx-auto" color="primary" style={{width: "90%"}} onClick={(evt) => searchSubmit(evt)}>Search</Button>
        </Col>
      </Row>
    </Container>
    <Container fluid className="element-box">
      <CustomTable columns={tableColumns} columnKeys={columnKeys} apiURL="https://jsonplaceholder.typicode.com/comments" dataReceivedCallback={dataReceivedCallback} />
    </Container>
  </Fragment>
  );
};

export default PageDOP;