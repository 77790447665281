import React, {useState, useEffect} from 'react';
import {Button, Form, FormGroup, Label, Input, Row, Col, Container} from 'reactstrap';

import logo from '../assets/logo.png';
import logoOn from '../assets/logo-on.png';

const PageMyAccount = function() {
	const [currentPassword, setCurrentPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [repeatNewPassword, setRepeatNewPassword] = useState("");

	const handleSubmit = (evt) => {
    evt.preventDefault();
  }

  useEffect(() => {
    document.title = "VLBC - My Account";
  }, []);

  return (
  	<Container fluid className="element-box">
  		<div className="title-box">
    	  <h1 className="page-title">Change Your Password</h1>
    	</div>
  		<Form onSubmit={handleSubmit}>
				<FormGroup>
					<Label>Your Current Password</Label>
					<Input type="password" placeholder="Enter Your Current Password" value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
				</FormGroup>
				<FormGroup>
					<Label>Your New Password</Label>
					<Input type="password" placeholder="Enter Your New Password" value={newPassword} onChange={e => setNewPassword(e.target.value)} />
				</FormGroup>
				<FormGroup>
					<Label>Repeat Your New Password</Label>
					<Input type="password" placeholder="Repeat Your New Password" value={repeatNewPassword} onChange={e => setRepeatNewPassword(e.target.value)} />
				</FormGroup>
				<Button type="submit" color="primary">Submit</Button>
			</Form>
  	</Container>
  );
};

export default PageMyAccount;