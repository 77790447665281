import React, {useState, useEffect, Fragment} from 'react';
import {Table, Spinner, Button} from 'reactstrap';
import {BrowserRouter as Router, Route, Switch, Link, Redirect} from "react-router-dom";

const CustomTable = function({columns, columnKeys, apiURL, dataReceivedCallback, addBeforePaginationInBar, addToPaginationInBar}) {
	const [page, setPage] = useState(0);
	const [pageSize, setPageSize] = useState(15);
	const [tableItems, setTableItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [maxPage, setMaxPage] = useState(0);
  const [pageNumbers, setPageNumbers] = useState([1,2,3,4,5]);

	const updateElements = function() {
    setIsLoading(true);
    fetch("https://api.instantwebtools.net/v1/passenger?page="+page+"&size="+pageSize)
      .then(response => response.json())
      .then(data => {
        setIsLoading(false);
        setMaxPage(data.totalPages-1);
        setTableItems(dataReceivedCallback(data.data));
      });
    updatePages();
	};

  const updatePages = function() {
    let amountOfPages = 4; //NOTE(muamer): Not including the page you're on, must be an even number!
    let availableToRight = maxPage - page;
    if (availableToRight > amountOfPages/2) availableToRight = amountOfPages/2;
    let count = 0, pages = Array(amountOfPages+1);
    for (let i = page - amountOfPages + availableToRight; (maxPage+1) > i; i++) {
      if (i < 0) continue;
      pages[count] = i;
      count++;
      if (count == amountOfPages+1) break;
    }
    setPageNumbers(pages);
  };

  const getTableHeading = function() {
    return (
      <thead>
        <tr>
          {columns.map(column => (
            <th className={column[1]}>{column[0]}</th>
          ))}
        </tr>
      </thead>
    );
  };

  const getTablePagination = function() {
    return (
      <div className="row pagsum">
        <div className="pagination-col col-xl-6">
          <ul className="pagination">
            {addBeforePaginationInBar}
            <li className={'page-item ' + (page < 1 ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(function(prevPage){if (prevPage > 0) return prevPage-1;})}>Previous</Link></li>
            <li className={'page-item ' + (page < 1 ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(0)}>First</Link></li>
            {pageNumbers.map(function(pageNumber) {
              return <li className={'page-item ' + (page == pageNumber ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(pageNumber)}>{pageNumber+1}</Link></li>
            })}
            <li className={'page-item ' + (page >= maxPage ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(maxPage)}>Last</Link></li>
            <li className={'page-item ' + (page >= maxPage ? 'disabled' : '')}><Link className="page-link" to="#" onClick={() => setPage(function(prevPage){if (prevPage < maxPage) return prevPage+1;})}>Next</Link></li>
            {addToPaginationInBar}
          </ul>
        </div>
        <div className="summaryline col-xl-6" style={{textAlign: "right"}}>
          Displaying
          <b> {pageSize*page+1} {" - "} {pageSize*(page+1)}</b>
          <i> ({pageSize}) </i>
          entries out of {pageSize*maxPage}
        </div>
      </div>
    );
  };

	useEffect(() => {
    updateElements();
  }, [page]);

  useEffect(() => {
    updatePages();
  }, [maxPage]);

  if (isLoading == true) {
    return (
      <div>
        <Table responsive hover>
          {getTableHeading()}
        </Table>
        <div className="d-flex justify-content-center">
          <Spinner color="primary" style={{width: "5rem", height: "5rem"}} type="grow"></Spinner>
        </div>
      </div>
    );
  }

	return (
  <div>
    <Table responsive hover>
      {getTableHeading()}
      <tbody>
      	{tableItems.map(item => (
          <tr key={item.__id}>
            {columnKeys.map(columnKey => (
              <td className={columnKey[1]}>{item[columnKey[0]]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
    {getTablePagination()}
  </div>
	);
}

export default CustomTable;